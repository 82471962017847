import 'core-js/stable'
import 'regenerator-runtime/runtime'
import objectFitImages from 'object-fit-images'
import 'slick-carousel'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

objectFitImages()

import Gallery from './gallery';
import Menu from './menu'
import Parallax from './parallax';
import Translate from './translate';

new Gallery();
new Menu();
new Parallax();
new Translate();

import Formbuilder from './formbuilder'
import Sliders from './sliders'
import { initMediaHandlers } from './media'

new Sliders()
new Formbuilder()
initMediaHandlers()
