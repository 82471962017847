import 'jquery-ujs'
import './remotipart';
import 'jquery-datetimepicker';
import 'jquery-datetimepicker/jquery.datetimepicker.css';

export default class Formbuilder {
  constructor() {
    $('.new_form_response').on('ajax:success', function(event, data) {
      if (!data.form) {
        $('html, body').animate({ scrollTop: $(event.currentTarget).offset().top - 32 }, 800);
        $(event.currentTarget).replaceWith(data.payload);
      } else {
        const form = data.form.form;

        if (form.page_redirect) {
          window.location.href = form.redirect_path;
        } else {
          $('html, body').animate({ scrollTop: $(event.currentTarget).offset().top - 32 }, 800);
          $(event.currentTarget).replaceWith(data.payload);
        }
      }
    });

    $('.new_form_response').on('ajax:error', function() {
      alert('something went wrong. Please try again or contact support team');
    });

    this.initForms();
  }

  initForms() {
    $('.date-input').datetimepicker({
      timepicker: false,
      scrollMonth: false,
      scrollInput: false,
      format: 'F j, Y',
      formatTime: 'g:i a'
    });

    $('.time-input').datetimepicker({
      datepicker: false,
      scrollMonth: false,
      scrollInput: false,
      format: 'g:i a',
      formatTime: 'g:i a',
      step: 15
    });
  }
}
