export default class Sliders {
  constructor() {
    this.promo = $('.promo');
    if (this.promo.children().length > 1) {
      this.slickify(this.promo);
    }

    $(window).on('load resize', () => {
      this.setHeights();
    })
  }

  slickify(slider) {
    slider.slick({
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1
    });
  }

  setHeights() {
    const spots = this.promo.find('.promo__spot');
    spots.outerHeight('auto');

    let newHeight = 0;

    spots.each((_, spot) => {
      if ($(spot).outerHeight() > newHeight) {
        newHeight = $(spot).outerHeight();
      }
    });

    spots.outerHeight(newHeight);
  }
}
