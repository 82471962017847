export default class Parallax {
  constructor() {
    if ($('.hero:not(.hero--blue)').length) {
      $(window).on('load resize scroll', () => {
        const windowWidth = $(window).width(),
              windowHeight = $(window).height();

        if (windowWidth >= 960) {
          $('.hero:not(.hero--blue)').each((_, hero) => {
            const heroRect = hero.getBoundingClientRect();
            const $hero = $(hero);
            let parallaxPercent = 0;

            if (heroRect.top >= windowHeight) {
              parallaxPercent = 0
            } else if (heroRect.top > -$hero.outerHeight() && heroRect.top < windowHeight) {
              let amountToScroll = $hero.outerHeight() + windowHeight;
              let amountScrolled = (windowHeight - $hero.offset().top) + $(window).scrollTop();
              parallaxPercent = amountScrolled / amountToScroll;

              $hero.find('.hero__image__img').css({
                marginTop: parallaxPercent * -160
              });
            } else {
              parallaxPercent = 1
            }
          });
        } else {
          $('.hero__image__img').removeAttr('style');
        }
      });
    }
  }
}