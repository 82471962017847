import debounce from 'lodash.debounce'

const handleMultilangButtonClick = event => {
  document.querySelector('.multilang__text').blur();

  let button = event.target
  button = button.closest('.multilang') || button
  button.classList.toggle('multilang--open')

  const options = button.querySelector(
    '.multilang__options'
  )

  if (options)
    options.classList.toggle('multilang__options--open')
}

const handleScroll = (prevScrollPos) => {
  let currentScrollPos = window.pageYOffset;
  let translateBtnOpen = document.querySelector('.multilang__options--open');

  if (prevScrollPos !== currentScrollPos && translateBtnOpen) {
    document.querySelector('.multilang__text').blur();

    document.querySelector('.multilang__options').classList.remove('multilang__options--open');
  }
}

export default class Translate {
  constructor() {
    const prevScrollPos = window.pageYOffset;
    const multilangButton = document.querySelector(
      '.multilang'
    )

    if (multilangButton)
      multilangButton.addEventListener('click', handleMultilangButtonClick)

    // Need to fire off the initial handler on page load before scrolling.
    handleScroll(prevScrollPos)
    window.addEventListener(
      'scroll',
      debounce(handleScroll, 100, { leading: true }),
      {
        passive: true,
      }
    )
  }
}